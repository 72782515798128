/** @jsx jsx */
import { jsx, Text, Link as ThemeLink, useColorMode } from "theme-ui";
import React from "react";
import { gridStyles, bodyColumn } from "../styles/shared.styles";
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Link as GatsbyLink } from "gatsby";
import SEO from "../components/seo";

const shortCodes = {
	GatsbyLink,
	ThemeLink,
	Text,
	jsx,
};

var { useEffect } = React;

const Blog = (props) => {
	var { allMdx } = props.data;

	var aboutContent = allMdx.edges[0].node;

	return (
		<>
			<SEO title="About" />
			<main sx={{ ...gridStyles, marginTop: 5 }} role="main">
				<div sx={bodyColumn}>
					<Text variant="display" sx={{ marginBottom: 5 }} as="h1">
						About me
					</Text>
					{/* <Text variant="body" sx={{ color: "grays.60" }}>
					An on-going experimentation of unpolished note taking, that
					includes the evolution of my working and thinking process.
				</Text> */}
					<MDXProvider components={shortCodes}>
						<MDXRenderer>{aboutContent.body}</MDXRenderer>
					</MDXProvider>
				</div>
			</main>
		</>
	);
};

export default Blog;

// gets access to all of my blog posts
export const query = graphql`
	query {
		allMdx(filter: { fileAbsolutePath: { regex: "/about/" } }) {
			edges {
				node {
					id
					frontmatter {
						title
						description
					}
					fileAbsolutePath
					fields {
						slug
					}
					body
				}
			}
		}
	}
`;

// https://res.cloudinary.com/gestalt/video/upload/v1588341358/jeremysbarnes/portfolio.mp4
// "https://res.cloudinary.com/gestalt/video/upload/q_auto,f_auto/v1588341358/jeremysbarnes/portfolio.mp4"
